<template>
  <div>
    <Modal class="mobile-pop-quotation" footer-hide v-model="showInquiry">
      <div class="top">
        <div class="product">{{ inquiry.product_name }}</div>
        <div class="status status-progress" v-if="inquiry.status == 1">
          In progress
        </div>
        <div class="status status-completed" v-if="inquiry.status == 2">
          Completed
        </div>
        <div class="status status-expired" v-if="inquiry.status == 3">
          Expired
        </div>
      </div>
      <div class="content">
        <div class="line-item">
          <div class="item">
            <div class="label">Quantity</div>
            <div class="value" :title="inquiry.quantity">
              {{ inquiry.quantity }}
            </div>
          </div>
          <div class="item">
            <div class="label">Specification</div>
            <div class="value" :title="inquiry.quality_standard">
              {{ inquiry.quality_standard || "--" }}
            </div>
          </div>

          <div class="item">
            <div class="label">Packaging</div>
            <div class="value" :title="inquiry.packaging">
              {{ inquiry.packaging || "--" }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Factory</div>
            <div class="value" :title="inquiry.factory">
              {{ inquiry.factory || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Lead Time</div>
            <div class="value" :title="inquiry.lead_time">
              {{ inquiry.lead_time || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Expiration Date</div>
            <div class="value">
              {{ inquiry.product_expire_date | sliceDate }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label" title="QC Documents/Factory Documents">
              QC Documents/Factory Documents
            </div>
            <div class="value search-button">
              <template v-if="inquiry.qc.length > 0">
                <a :href="inquiry.qc[0].url" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.68 13.89C23.1338 9.5875 19.45 6.25 15 6.25C11.555 6.25 8.5625 8.2625 7.19625 11.4363C4.51125 12.24 2.5 14.775 2.5 17.5C2.5 20.8213 5.11125 23.5188 8.385 23.7138V23.75H22.4875V23.7462L22.5 23.75C25.2575 23.75 27.5 21.5075 27.5 18.75C27.4985 17.6294 27.1214 16.5416 26.4289 15.6606C25.7364 14.7796 24.7685 14.1562 23.68 13.89V13.89ZM10 15H13.75V11.25H16.25V15H20L15 21.25L10 15Z"
                      fill="#F57F45"
                    />
                  </svg>
                </a>
              </template>
              <template v-else>--</template>
            </div>
          </div>
          <div class="item">
            <div class="label">COA Attached</div>
            <div class="value search-button">
              <template v-if="inquiry.coa.length > 0">
                <a :href="inquiry.coa[0].url" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.68 13.89C23.1338 9.5875 19.45 6.25 15 6.25C11.555 6.25 8.5625 8.2625 7.19625 11.4363C4.51125 12.24 2.5 14.775 2.5 17.5C2.5 20.8213 5.11125 23.5188 8.385 23.7138V23.75H22.4875V23.7462L22.5 23.75C25.2575 23.75 27.5 21.5075 27.5 18.75C27.4985 17.6294 27.1214 16.5416 26.4289 15.6606C25.7364 14.7796 24.7685 14.1562 23.68 13.89V13.89ZM10 15H13.75V11.25H16.25V15H20L15 21.25L10 15Z"
                      fill="#F57F45"
                    />
                  </svg>
                </a>
              </template>
              <template v-else>--</template>
            </div>
          </div>
          <div class="item">
            <div class="label">Basis and price</div>
            <div class="value" :title="inquiry.basis_and_price">
              {{ inquiry.basis_and_price || "--" }}
            </div>
          </div>
        </div>
      </div>
      <div class="info" v-if="showInfo">
        <div class="info-title">Contact Details:</div>
        <div class="info-content">
          <div class="item">
            <div class="label">Company :</div>
            <div
              class="value value-company search-button"
              :title="infoParam.company_name"
              @click="viewCompanyQuotation()"
            >
              {{ infoParam.company_name || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Name :</div>
            <div class="value" :title="infoParam.user">
              {{ infoParam.user || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Email :</div>
            <div class="value" :title="infoParam.email">
              {{ infoParam.email || "--" }}
            </div>
          </div>
        </div>
      </div>
      <template v-if="inquiry.status == 1 && !inquiry.is_my_inquiry">
        <div class="alert">
          <div class="left">
            <svg
              width="8"
              height="48"
              viewBox="0 0 8 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 32L8 16L0 24L8 32Z" fill="#373D3F" />
            </svg>
          </div>
          <div class="right">
            If your company wants to buy this product, please bid here.
          </div>
        </div>
        <div class="button" v-if="inquiry.is_involved == 0">
          <div class="cancel search-button" @click="cancel">Cancel</div>
          <div class="save search-button" @click="save" v-if="!btnLoading">
            Save
          </div>
          <div class="save search-button loading-button" v-else>
            <img src="@/assets/loading.gif" />
          </div>
        </div>
        <div class="button" v-else>
          <div class="expired-cancel search-button" @click="cancel">Cancel</div>
        </div>
      </template>
      <template v-if="inquiry.status == 2">
        <div class="content"></div>
        <div class="button">
          <div class="expired-cancel search-button" @click="cancel">Cancel</div>
        </div>
      </template>
      <template v-if="inquiry.status == 3">
        <div class="button">
          <div class="expired-cancel search-button" @click="cancel">Cancel</div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
const { _quoteInquiry } = api;
import { mapState } from "vuex";

export default {
  props: ["inquiry"],
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      inquiryForm: { price: "", note: "", id: "" },
      showInquiry: false,
      loading: false,
      showInfo: false,
      infoParam: { company_name: "", user: "", email: "" },
      btnLoading: false
      // inquriy: {}
    };
  },
  mounted() {},
  filters: {
    sliceDate(val) {
      if (val) {
        return val.slice(0, 10);
      } else {
        return null;
      }
    }
  },
  methods: {
    viewCompanyQuotation() {},
    cancel() {
      this.showInquiry = false;
    },
    save() {
      this.loading = true;
      this.btnLoading = true;
      if (this.role === "") {
        this.loading = false;
        this.btnLoading = false;
        this.$Message.error("Please log in first");
        return;
      }
      this.inquiryForm.id = this.inquiry.id;
      this.inquiryForm.type = 2;
      _quoteInquiry({ ...this.inquiryForm })
        .then(data => {
          this.$Message.success("success");
          this.$emit("getSourcings");
          this.showInfo = true;
          this.infoParam.company_name = data.data.company.company_name;
          this.infoParam.user =
            data.data.user.first_name + " " + data.data.user.last_name;
          this.infoParam.email = data.data.user.email;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.btnLoading = false;
          // this.loading = false;
          // this.showInquiry = false;
          // this.inquiryForm.price = "";
          // this.inquiryForm.note = "";
          this.showInfo = true;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.mobile-pop-quotation {
  padding: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    .product {
      font-size: 20px;
      font-weight: 600;
      color: #222;
    }
    .status {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center !important;
      color: #fff;
      font-size: 13px;
      align-items: center;
    }
    .status-progress {
      background: #11828f;
    }
    .status-expired {
      background: #c0399f;
    }
    .status-completed {
      background: #925acf;
    }
  }
  .content {
    .line-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .item {
        width: 33%;
        .label {
          color: #8a8a8a;
          font-size: 14px;
          padding-bottom: 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .value {
          color: #444;
          font-size: 15px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .button {
    display: flex;
    justify-content: right;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .expired-cancel {
      background: #bababa;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
    }
    .save {
      background: #f57f45;
      color: #fff;
      width: 75px;
      height: 35px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
.input-value {
  margin-bottom: 15px;
  .label {
    color: #8a8a8a;
    font-size: 14px;
    padding-bottom: 3px;
  }
  .input {
    input {
      border: 2px solid #dfdfe6;
      outline: none;
      width: 200px;
      height: 40px;
      border-radius: 12px;
      padding-left: 10px;
      margin-top: 10px;
    }
    input:focus {
      border: 2px solid #f57f45;
    }
  }
  .textarea {
    textarea {
      border: 1px solid #c1c1c1;
      outline: none;
      width: 200px;
      border-radius: 5px;
      padding-left: 10px;
    }
  }
}
.alert {
  position: relative;
  width: 0;
  height: 0;
  left: 210px;
  bottom: 130px;
  .right {
    width: 287px;
    font-size: 14px;
    height: 56px;

    /* Grays/Casper/CA-700 */

    background: #334155;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
    color: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px;
  }
  .left {
    position: relative;
    right: 8px;
    top: 70px;
  }
}
.company {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    margin-top: 5px;
    padding-bottom: 10px;
    .icon {
      position: relative;
      top: 4px;
      right: 5px;
    }
    .name {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    .item {
      width: 33%;
      .label {
        font-size: 14px;
        color: #8a8a8a;
        padding-left: 20px;
      }
      .value {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        padding-left: 20px;
        word-wrap: break-word;
      }
      .show-value {
        white-space: normal !important;
        word-wrap: break-word;
      }
    }
  }
}
.info {
  background: #ebebeb;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  .info-title {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 10px 0;
  }
  .info-content {
    .item {
      display: flex;
      margin-bottom: 10px;
      .label {
        margin-right: 5px;
        width: 100px;
        font-weight: 600;
      }
      .value {
        font-weight: 600;
      }
      .value-company {
        color: #f57f45;
      }
    }
  }
}
</style>

<style lang="less">
.mobile-pop-quotation {
  .ivu-modal {
    width: auto !important;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
